<template>
  <div v-bind:class="[isLight ? 'background-light' : 'background-dark', 'resume-page']">
       <div class="page" style="top: 50%;  ; position: relative;"> <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">At the moment this page is still under construction. It will be updated very soon! </h1></div>

  </div>
</template>

<script>
export default {
  name: "OtherProjectsPage",
  components: {},
  mounted() {
    window.scrollTo(0, 0);
    this.$forceUpdate();
  },
  methods: {},
  computed: {
    isLight() {
      let light = this.$store.state.viewMode === "light";
      if (localStorage.getItem("mode") === "light") light = true;
      else light = false;
      return light;
    },
  },
  data() {
    return {};
  },
};
//   {left: "Creating visualisations", center: "Viewing visualisations", right: ""},
</script>

<style lang="scss">
.col-right {
  padding-right: 16px;
}

.text-black {
  color: #202020;
  font-weight: 500;
}
.resume-page {
    min-height: 100vh;
}
.column-tight {
  float: left;
  width: 50%;
  text-align: left;
  padding: 0 0 40px 40px;
}
.flex-container {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: center;
   padding: 0 0 0px 32px;
}

.flex-item-left {
  padding: 10px;
  flex: 50%;
  text-align: left;

}

.flex-item-right {
  padding: 10px;
  flex: 50%;
  text-align: left;
}

/* Clear floats after the columns */
.row-tight:after {
  content: "";
  display: table;
  clear: both;
}

.background-dark {
  background-color: #202020;
}
.background-light {
  background-color: #f3f3f3;
}
.contourPage {
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100vw;
  background-color: #f3f3f3;
  z-index: 1;
  color: white;
  overflow-x: hidden;
}

.content {
  margin-bottom: 10px;
  position: relative;
  text-align: center;
  height: 100%;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  max-width: 1250px;
}
@media only screen and (max-width: 900px) {
  .flex-container {
    flex-direction: column;
  }
  .flex-container {
   padding: 0 0 0px 0px;
}
.flex-item-left > .body-text-light{
    padding-bottom: 0
}
.flex-item-right {
    padding-left: 32px;
    padding-bottom: 48px;
}
  .page:nth-child(1) {
    margin-top: 20%;
  }
  .page {
    width: 100vw;
    padding: 4px;
    margin-bottom: 24px;
    border-radius: 50px;
  }
  .background {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .flex-item-left p:nth-of-type(1){
    font-weight: 600;
  }
  .section {
    padding: 0 0 0 0;
    margin: 0 0 0 0;
  }
  .columnLeft {
    padding-bottom: 20px;
    padding-top: 24px;
  }

  .columnRight {
    padding-bottom: 32px;
    padding-top: 0;
  }
}
</style>
